import React, { useState } from "react"
import PropTypes from "prop-types"
import Link from "gatsby-link"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import getLoginURL from "@helpers/get_login_url"
import links from "@data/header/links.json"
import FestivalsSignupModal from "@page-components/festivals/signup-modal"
import Dropdown from "./dropdown"

const Desktop = ({ dark, pinned }) => {
  const login_url = getLoginURL()
  const [showModal, setShowModal] = useState(false)
  return (
    <div className="flex-auto xs-hide sm-hide md-flex items-center justify-end">
      {links.links
        .filter(link => link.show.includes("DESKTOP"))
        .map((link, i) => (
          <div key={link._id} className={`mr2 px1 ${link.spacer ? "flex-auto" : ""} ${i === 0 ? "ml4" : ""}`}>
            {link.spacer ? null : link.children ? (
              <Dropdown link={link} pinned={pinned} />
            ) : link.internal ? (
              <Link to={link.to} style={{ color: pinned ? "#383E43" : "#ffffff", fontSize: "16px" }}>
                {link.label}
              </Link>
            ) : (
              <OutboundLink
                href={link.to.replace("{{LOGIN}}", login_url)}
                style={{ color: pinned ? "#383E43" : "#ffffff", fontSize: "16px" }}
                rel="noopener noreferrer"
                target="_blank">
                {link.label}
              </OutboundLink>
            )}
          </div>
        ))}
      {["/products/festivals/", "/products/festivals"].includes(window.location.pathname) ? (
        <button
          onClick={() => setShowModal(true)}
          tabIndex="-1"
          style={{ width: "110px" }}
          className={`small ${pinned ? "" : "web_white"} ml1`}>
          <div style={{ color: dark && !pinned ? "#222" : "" }}>Sign Up</div>
        </button>
      ) : (
        <OutboundLink
          target="_blank"
          className="ml1"
          rel="noopener noreferrer"
          href="https://accounts.cinesend.com/signup">
          <button tabIndex="-1" style={{ width: "110px" }} className={`small ${pinned ? "" : "web_white"}`}>
            <div style={{ color: dark && !pinned ? "#222" : "" }}>Sign Up</div>
          </button>
        </OutboundLink>
      )}

      {showModal && <FestivalsSignupModal onClose={() => setShowModal(false)} />}
    </div>
  )
}

Desktop.propTypes = {
  dark: PropTypes.bool.isRequired,
  pinned: PropTypes.bool.isRequired
}

export default Desktop
