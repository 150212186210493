import React from "react"
import { css } from "emotion"
import PropTypes from "prop-types"
import Plx from "react-plx"

const Fade = ({ up, opacity, className, tagName, children }) => (
  <Plx
    tagName={tagName}
    className={css`
      opacity: ${opacity ? 0 : 1};
      transform: ${up ? "translateY(90px)" : ""};
    `}
    parallaxData={[
      {
        start: "self",
        duration: 300,
        properties: [
          {
            startValue: 0,
            endValue: 1,
            property: "opacity",
            show: opacity
          },
          {
            startValue: 90,
            endValue: 0,
            property: "translateY",
            show: up
          }
        ].filter(item => item.show)
      }
    ]}>
    <div className={className}>{children}</div>
  </Plx>
)

Fade.defaultProps = {
  opacity: false,
  up: false,
  className: ""
}

Fade.propTypes = {
  children: PropTypes.node.isRequired,
  opacity: PropTypes.bool,
  up: PropTypes.bool,
  className: PropTypes.string,
  tagName: PropTypes.string.isRequired
}

export default Fade
