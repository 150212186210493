const landingAnimationCSS = `
  svg[data-svg-id='svg-deliver'], svg[data-svg-id='svg-convert'], svg[data-svg-id='svg-users'] {
    width: 220px;
  }

  svg[data-svg-id='svg-deliver'],  {
    [data-svg-id='_1'], [data-svg-id='_2'], [data-svg-id='_3'] {
      will-change: transform;
      opacity: 0;
      transform: scale(0.9) translateY(25%);
      transition: all .8s ease-in;
      transition-delay: 0s;
    }
    [data-svg-id='_2'] {
      transition-delay: .3s;
    }
    [data-svg-id='_3'] {
      transition-delay: .4s;
    }
    &.svg-active {
      [data-svg-id='_1'], [data-svg-id='_2'], [data-svg-id='_3'] {
        opacity: 1;
        transform: scale(1) translateY(5%);
      }
    }
  }

  svg[data-svg-id='svg-convert'] {
    [data-svg-id='_1'] {
      will-change: transform;
      opacity: 0;
      transform: scale(0.5);
      transition: all .9s ease-in;
      transition-delay: .3s;
      transform-origin: center;
    }
    &.svg-active {
      [data-svg-id='_1'], [data-svg-id='_2'], [data-svg-id='_3'] {
        opacity: 1;
        transform: scale(0.9);
      }
    }
  }

  svg[data-svg-id='svg-users'] {
    [data-svg-id='_1'], [data-svg-id='_2'] {
      will-change: transform;
      opacity: 0;
      transform: scale(0.9) translateY(10%);
      transition: all 1s ease-in;
      transition-delay: .5s;
      transform-origin: center;
    }
    [data-svg-id='_2'] {
      transition-delay: .9s;
    }
    &.svg-active {
      [data-svg-id='_1'], [data-svg-id='_2'] {
        opacity: 1;
        transform: scale(1) translateY(0%);
      }
    }
  }
`

export default landingAnimationCSS
