import React from "react"
import PropTypes from "prop-types"
import "whatwg-fetch"
import smoothscroll from "smoothscroll-polyfill"
import { useMount } from "react-use"
import { setConfig } from "react-hot-loader"
import Header from "@app-components/navigation/header/"
import Footer from "@app-components/navigation/footer/"
import PageTitle from "@app-components/utils/page_title"
import loadFonts from "@helpers/load_fonts"
import Styles from "@styles/"
import Link from "gatsby-link"
import { css } from "@emotion/core"

setConfig({ pureSFC: true })

// Going to leave this for if we ever need to quickly add these types of headers back in.
const WARNING_PAGES = [
  // "products/festivals",
  // "products/festivals/pricing",
  // "products/dcp-manager",
  // "products/dcp-manager/pricing"
]

const WARNING_MESSAGE = "COVID-19 Response: Options for festivals at risk of cancellation"

const Layout = ({ children, page, getStartedHeader, getStartedText, getStartedButtons }) => {
  useMount(() => {
    loadFonts()
    smoothscroll.polyfill()
    if (process.env.NODE_ENV !== "development") {
      window.scroll({ top: 0, left: 0, behavior: "smooth" })
    }
  })
  return (
    <React.Fragment>
      <Styles />
      <PageTitle page={page} />
      {WARNING_PAGES.includes(page) && (
        <Link to="/products/virtual-festival">
          <div
            className="flex items-center justify-center py1"
            css={css`
              background: #ffeb3b;
              color: black;
              transition: all 300ms;

              &:hover {
                background: #fdd835;
              }
            `}>
            {WARNING_MESSAGE}
          </div>
        </Link>
      )}
      <Header page={page} />
      {children}
      <Footer
        page={page}
        getStartedHeader={getStartedHeader}
        getStartedText={getStartedText}
        getStartedButtons={getStartedButtons}
      />
    </React.Fragment>
  )
}

Layout.defaultProps = {
  getStartedHeader: "Ready to get started?",
  getStartedText: "Sign up today to start managing your content like a pro."
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  page: PropTypes.string.isRequired,
  getStartedText: PropTypes.string,
  getStartedHeader: PropTypes.string,
  getStartedButtons: PropTypes.array.isRequired
}

export default Layout
