import React, { useState } from "react"
import PropTypes from "prop-types"
import Link from "gatsby-link"
import { Modal } from "@bitcine/cs-theme"
import { css } from "@emotion/core"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Background from "@app-components/utils/background"
import Fade from "@app-components/transition/fade"
import { ReactComponent as RadarSVG } from "@media/svg/radar.svg"

const CTA = ({ getStartedButtons, getStartedHeader, getStartedText }) => {
  const [showModal, setShowModal] = useState(false)
  const [email, setEmail] = useState("")
  const [sending, setSending] = useState(false)
  return (
    <Background
      tagName="section"
      className="bg-cover bg-no-repeat bg-center center white px2 md-px3 py5 overflow-hidden"
      type="red-single">
      <Fade tagName="div" opacity>
        <div className="max-width-5 mx-auto">
          <h2>{getStartedHeader}</h2>
          <p className="large-p-tag">{getStartedText}</p>
          <div className="block sm-flex items-center justify-center mt3">
            {getStartedButtons.includes("email") && (
              <div className="flex items-center">
                <input value={email} onChange={e => setEmail(e.target.value)} placeholder="Enter your email" />
                <button
                  tabIndex="-1"
                  className="web_white ml2"
                  disabled={sending}
                  onClick={() => {
                    setSending(true)
                    setEmail("")
                    const url = "https://api.cinesend.com/api/website/contact"
                    const message = "Hi there, I'm using the request-a-call feature. Please reach out."
                    fetch(`${url}?email=${email.trim()}&message=${message}`, {
                      method: "POST"
                    })
                      .then(res => res.json())
                      .then(() => {
                        setShowModal("success")
                        setSending(false)
                      })
                      .catch(() => setShowModal("error"))
                  }}>
                  Request a call
                </button>
                {showModal && (
                  <Modal
                    open={!!showModal}
                    onClose={() => setShowModal(false)}
                    title={showModal === "success" ? "Success!" : "An error occurred."}>
                    {showModal === "success" ? (
                      <p>Thanks for reaching out. We&apos;ll get back to you shortly.</p>
                    ) : (
                      <p>Please reach out directly to support@cinesend.com.</p>
                    )}
                  </Modal>
                )}
              </div>
            )}
            {getStartedButtons.includes("get-started") && (
              <OutboundLink
                href="https://accounts.cinesend.com/signup"
                target="_blank"
                rel="noopener noreferrer"
                className="mx1 my1 block sm-inline-block">
                <button
                  tabIndex="-1"
                  css={css`
                    width: 155px;
                  `}
                  className="web_white">
                  Get started
                </button>
              </OutboundLink>
            )}
            {getStartedButtons.includes("demo") && (
              <Link to="/demo" className="mx1 my1 block sm-inline-block">
                <button
                  tabIndex="-1"
                  css={css`
                    width: 155px;
                  `}
                  className="web_white_outline">
                  Schedule a demo
                </button>
              </Link>
            )}
            {getStartedButtons.includes("contact-sales") && (
              <a
                href="mailto:hello@cinesend.com"
                target="_blank"
                rel="noopener noreferrer"
                className="mx1 my1 block sm-inline-block">
                <button
                  tabIndex="-1"
                  css={css`
                    width: 155px;
                  `}
                  className="web_white">
                  Contact sales
                </button>
              </a>
            )}
            {getStartedButtons.includes("contact-festivals") && (
              <a
                href="mailto:festivals@cinesend.com"
                target="_blank"
                rel="noopener noreferrer"
                className="mx1 my1 block sm-inline-block">
                <button
                  tabIndex="-1"
                  css={css`
                    width: 155px;
                  `}
                  className="web_white">
                  Contact
                </button>
              </a>
            )}
          </div>
          <RadarSVG
            css={css`
              margin-bottom: -26rem;
            `}
            className="col-12 max-width-2 mt3 xs-hide sm-block md-block lg-block mx-auto"
          />
        </div>
      </Fade>
    </Background>
  )
}

CTA.propTypes = {
  getStartedText: PropTypes.string.isRequired,
  getStartedHeader: PropTypes.string.isRequired,
  getStartedButtons: PropTypes.array.isRequired
}

export default CTA
