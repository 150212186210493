import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import { css, keyframes } from "@emotion/core"
import { useOutsideClick, useKeyPress } from "react-use"
import Anchor from "./anchor"

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Dropdown = ({ link, pinned }) => {
  const [isOpen, toggle] = useState(false)
  const dropdownRef = useRef()
  const close = () => {
    if (isOpen) {
      toggle(false)
    }
  }
  const open = e => {
    e.preventDefault()
    toggle(!isOpen)
  }
  useOutsideClick(dropdownRef, () => close())
  if (useKeyPress("Escape") && isOpen) {
    close()
  }
  return (
    <div ref={dropdownRef} className="relative">
      <a
        href=""
        css={css`
          color: ${pinned ? "#383E43" : "#ffffff"} !important;
          font-size: 16px;
          font-weight: ${isOpen ? "bold" : "normal"};
        `}
        className="flex items-center"
        onClick={open}>
        {link.label}
        <span
          className="material-icons ml1"
          css={css`
            transition: transform 0.2s linear;
            transform: rotate(${isOpen ? "180deg" : "0deg"});
          `}>
          keyboard_arrow_down
        </span>
      </a>
      {isOpen && (
        <div
          className="pt2 absolute top-100"
          css={css`
            left: 50%;
            transform: translateX(-50%);
            opacity: 0;
            animation: ${fade} 0.2s linear forwards;
            width: ${link.width};
          `}>
          <div
            className="bg-white absolute"
            css={css`
              left: calc(50% - 6px);
              width: 12px;
              height: 12px;
              top: 12px;
              transform: rotate(45deg);
            `}
          />
          <div
            css={css`
              box-shadow: 0 6px 19px 0 rgba(98, 98, 98, 0.59);
            `}
            className="bg-white p1 rounded">
            {link.children.map((child, index) => (
              <div key={index}>
                {child.label && <small className="block border-bottom border-gray-5 muted">{child.label}</small>}
                <div className="flex flex-wrap items-center">
                  {child.links
                    .filter(cld => !cld.hide)
                    .map((dropdownLink, i) => (
                      <Anchor link={dropdownLink} key={i} />
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

Dropdown.propTypes = {
  link: PropTypes.object.isRequired,
  pinned: PropTypes.bool.isRequired
}

export default Dropdown
