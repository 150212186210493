import React, { useState, useEffect } from "react"
import ReactDom from "react-dom"
import PropTypes from "prop-types"
import CineSendByBitCine from "@app-components/utils/cinesend_by_bitcine"
import { useKeyPress } from "react-use"
import { css } from "@emotion/core"
import links from "@data/header/links.json"
import Row from "./row"
import Close from "./close"
import Open from "./open"

const Mobile = ({ pinned }) => {
  const [open, toggle] = useState(false)
  const onOpen = e => {
    e.preventDefault()
    toggle(true)
  }
  if (useKeyPress("Escape") && open) {
    toggle(false)
  }
  useEffect(
    () => {
      if (open) {
        document.body.style.overflow = "hidden"
      } else {
        document.body.style.overflow = "scroll"
      }
    },
    [open]
  )
  return (
    <div className="flex md-hide lg-hide flex-auto items-center justify-end">
      <div className="relative">
        <Open pinned={pinned} open={onOpen} />
        {typeof window !== "undefined" &&
          ReactDom.createPortal(
            <React.Fragment>
              {open && (
                <div
                  className="fixed top-0 right-0 bottom-0 left-0 z4"
                  css={css`
                    background: rgba(52, 54, 56, 0.42);
                    transition: all 0.2s ease-in-out;
                  `}
                />
              )}
              <div
                css={css`
                  max-width: 320px;
                  box-shadow: ${open ? "0 0 9px 0 rgba(0,0,0,.53)" : ""};
                  transform: translateX(${open ? "0%" : "100%"});
                  transition: transform 0.2s ease-in-out;
                  z-index: 100;
                `}
                className="bg-white col-12 overflow-scroll relative p2 fixed top-0 right-0 bottom-0">
                <div className="flex items-center justify-between col-12 mb2">
                  <CineSendByBitCine />
                  <Close toggle={toggle} />
                </div>
                <div className="col-12 flex-auto">
                  {links.links.filter(link => link.show.includes("MOBILE")).map((link, index) => (
                    <Row toggle={() => toggle(false)} link={link} key={index} />
                  ))}
                </div>
              </div>
            </React.Fragment>,
            document.body
          )}
      </div>
    </div>
  )
}

Mobile.propTypes = {
  pinned: PropTypes.bool.isRequired
}

export default Mobile
