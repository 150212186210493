import React from "react"
import Copywright from "./copywright"
import Links from "./links"

const Base = () => (
  <footer className="px2">
    <nav className="flex flex-wrap pt3 md-pt4 items-start max-width-5 mx-auto">
      <Links />
    </nav>
    <Copywright />
  </footer>
)

export default Base
