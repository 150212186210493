import React from "react"
import Link from "gatsby-link"
import { css } from "@emotion/core"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import CineSendByBitCine from "@app-components/utils/cinesend_by_bitcine"
import links from "@data/footer/links.json"
import getLoginURL from "@helpers/get_login_url"

const Links = () => (
  <React.Fragment>
    <div className="col-12 mb3">
      <Link to="/" className="inline-flex items-center " title="Go to Homepage">
        <CineSendByBitCine
          alt="CineSend"
          css={css`
            width: 150px;
            height: 31px;
          `}
        />
      </Link>
    </div>
    <div className="col-12 flex flex-wrap items-start">
      {links.categories.map((category, index) => (
        <div key={index} className="col-12 sm-col-4 md-col-3 lg-col-2 mb3 pr0 md-pr2">
          <strong className="mb1 block">{category.title}</strong>
          {category.links.map(
            (link, i) =>
              link.internal ? (
                <Link
                  key={i}
                  to={link.to}
                  className="nowrap flex items-center"
                  css={css`
                    height: 2em;
                    &:hover,
                    &:focus {
                      color: #fb0f3b;
                    }
                  `}
                  activeClassName="bold"
                  title={`View ${link.label}`}>
                  {link.label}
                </Link>
              ) : (
                <OutboundLink
                  href={link.to.replace("{{LOGIN}}", getLoginURL())}
                  key={i}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nowrap flex items-center"
                  css={css`
                    height: 2em;
                    &:hover,
                    &:focus {
                      color: #fb0f3b;
                    }
                  `}>
                  {link.label}
                </OutboundLink>
              )
          )}
        </div>
      ))}
    </div>
  </React.Fragment>
)

export default Links
