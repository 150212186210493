import React, { useState } from "react"
import PropTypes from "prop-types"
import { Modal } from "@bitcine/cs-theme"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const FestivalsSignupModal = ({ onClose }) => {
  const [name, setName] = useState("")
  return (
    <div>
      <Modal title="Festivals Sign Up" open width={1} clickOutsideCloses onClose={onClose}>
        <div className="col-12">
          <label htmlFor="name">Festival Name</label>
          <input
            autoFocus
            value={name}
            onChange={e => setName(e.target.value)}
            id="name"
            className="col-12"
            type="text"
            placeholder="The Toronto International Film Festival"
          />

          <div className="right-align mt3 border-bottom border-gray-4 pb3">
            <OutboundLink
              target="_blank"
              rel="noopener noreferrer"
              href={`https://accounts.cinesend.com/signup?festival=${encodeURI(name)}`}>
              <button disabled={!name.length}>Continue &rarr;</button>
            </OutboundLink>
          </div>

          <div className="center pt1">
            Not a festival? &nbsp;
            <OutboundLink target="_blank" rel="noopener noreferrer" href="https://accounts.cinesend.com/signup">
              <button className="link">Click here to create a regular account</button>.
            </OutboundLink>
          </div>
        </div>
      </Modal>
    </div>
  )
}

FestivalsSignupModal.propTypes = {
  onClose: PropTypes.func.isRequired
}

export default FestivalsSignupModal
