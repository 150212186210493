import Cookie from "js-cookie"

const base = "https://accounts.cinesend.com/login"

const apps = {
  files: "https://app.cinesend.com",
  bookings: "https://bookings.cinesend.com",
  venues: "https://venues.cinesend.com",
  festivals: "https://festivals.cinesend.com"
}

const getLoginURL = () => {
  if (typeof window === "undefined") {
    return base
  }
  const app = Cookie.get("active-application")
  if (!app || !Object.prototype.hasOwnProperty.call(apps, app)) {
    return base
  }
  return apps[app]
}

export default getLoginURL
