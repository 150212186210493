import React from "react"
import PropTypes from "prop-types"
import close from "@media/icons/nav/close.svg"
import { css } from "@emotion/core"

const Close = ({ toggle }) => (
  <button
    onClick={() => toggle(false)}
    className="link flex items-center justify-center"
    css={css`
      border-radius: 50%;
      width: 40px;
      height: 40px;
      :hover,
      :focus {
        background: #ededed;
      }
    `}>
    <div className="center">
      <img
        alt="Close"
        src={close}
        css={css`
          width: 14px;
        `}
      />
      <small
        css={css`
          font-size: 8px;
        `}
        className="bold block">
        CLOSE
      </small>
    </div>
  </button>
)

Close.propTypes = {
  toggle: PropTypes.func.isRequired
}

export default Close
