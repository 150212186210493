import React from "react"
import PropTypes from "prop-types"
import Link from "gatsby-link"
import { css } from "@emotion/core"
import { css as c } from "emotion"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Icon from "@app-components/utils/icon"

const gray = "#f9f9f9"

const Anchor = ({ link }) =>
  link.internal ? (
    <Link
      to={link.to}
      activeClassName={c`
        > div {
          background: ${gray};
        }
      `}
      className="nowrap rounded col-12"
      css={css`
        :hover {
          > div {
            background: ${gray};
          }
          strong {
            font-weight: bold;
          }
        }
      `}>
      <div
        css={css`
          transition: all 0.2s linear;
        `}
        className={`flex items-center ${link.description ? "p2" : "p1"}`}>
        {link.icon && <Icon className="mr2" size="42" icon={link.icon} hideBorder={link.hideBorder} />}
        <div className="flex-auto">
          <span className={`block ${link.description ? "bold" : ""}`}>{link.label}</span>
          {link.description && <p className="muted mb0">{link.description}</p>}
        </div>
      </div>
    </Link>
  ) : (
    <OutboundLink
      href={link.to}
      target="_blank"
      rel="noopener noreferrer"
      activeClassName={c`
        > div {
          background: ${gray};
        }
      `}
      className={`${link.icon && "py1"} nowrap rounded col-12`}
      css={css`
        :hover {
          > div {
            background: ${gray};
          }
          strong {
            font-weight: bold;
          }
        }
      `}>
      <div
        css={css`
          transition: all 0.2s linear;
        `}
        className="flex items-center p1">
        {link.icon && <Icon className="mr2" size="42" icon={link.icon} hideBorder={link.hideBorder} />}
        <div className="flex-auto">
          <span className={`block ${link.description ? "bold" : ""}`}>{link.label}</span>
          {link.description && <p className="muted mb0">{link.description}</p>}
        </div>
      </div>
    </OutboundLink>
  )

Anchor.propTypes = {
  link: PropTypes.object.isRequired
}

export default Anchor
