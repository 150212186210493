const loadFonts = () => {
  if (!document.getElementsByTagName("html")[0].className.includes("wf-active")) {
    const script = document.createElement("script")
    script.src = "https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js"
    script.onload = () => {
      WebFont.load({
        google: {
          families: ["Roboto:400,700", "Material+Icons"]
        }
      })
    }
    document.head.appendChild(script)
  }
}

export default loadFonts
